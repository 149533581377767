<template>
  <div class="table">
    <div class="search-top">
      <div class="input">
        <span>兑换码：</span>
        <el-input
          v-model="params.keyword"
          placeholder="模糊搜索(支持兑换码、优惠券标题)"
        ></el-input>
      </div>
      <div>
        <span>优惠券组：</span>
        <el-select v-model="params.coupon_group_id" filterable>
          <el-option
            v-for="item in groupList"
            :key="item.coupon_group_id"
            :value="item.coupon_group_id"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <div>
        <span>状态：</span>
        <el-select v-model="params.status">
          <el-option :value="null" label="全部"></el-option>
          <el-option value="1" label="启用"></el-option>
          <el-option value="0" label="停用"></el-option>
        </el-select>
      </div>
      <el-button @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </div>
    <header class="header">
      <switch-text
        v-model="flag"
        left="手动编辑"
        right="自动生成"
      ></switch-text>
      <div>
        <el-button
          type="primary"
          style="border-radius: 16px"
          @click="bannerEdit"
          >+新建</el-button
        >
      </div>
    </header>
    <DragTable
      :list-query="list"
      :header="header"
      :sort="false"
      @sort-change="sortChange"
    >
      <template #state="{ row }">
        <span class="status" :class="{ active: row.status !== 1 }">{{
          row.status === 1 ? `启用` : `停用`
        }}</span>
      </template>
      <template #time="{ row }">
        <span>{{
          (row.created_at &&
            $dayjs(row.created_at * 1000).format("YYYY-MM-DD HH:mm:ss")) ||
          ""
        }}</span>
      </template>
      <template #total="{ row }">{{ row.total - row.used }}</template>
      <template #control="{ row }">
        <div class="control">
          <span v-if="flag" @click="bannerEdit(row)">编辑</span>
          <span :class="{ active: row.status === 1 }" @click="open(row)">{{
            row.status === 0 ? "启用" : "停用"
          }}</span>
          <span v-if="!flag" @click="read(row)">查看</span>
        </div>
      </template>
    </DragTable>
    <footer class="table-footer">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.per_page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </footer>
    <go-dialog v-model="visible" width="350px" height="400px">
      <h2>
        {{
          flag
            ? `手动${couponInfo.data.coupon_code_id ? "编辑" : "新建"}兑换码`
            : `自动生成兑换码`
        }}
      </h2>
      <div class="coupon-item">
        <span>优惠券组名:</span>
        <el-select
          v-model="couponInfo.data.b_coupon_group_id"
          :disabled="
            couponInfo.data.coupon_code_id || couponInfo.data.coupon_code_id === 0
          "
          filterable
        >
          <el-option
            v-for="item in groupList"
            :key="item.coupon_group_id"
            :value="item.coupon_group_id"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <div class="coupon-item">
        <span>优惠券名:</span>
        <el-select
          v-model="couponInfo.data.coupon_id"
          :disabled="
            couponInfo.data.coupon_code_id || couponInfo.data.coupon_code_id === 0
          "
        >
          <el-option
            v-for="item in couponList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <div class="coupon-item" v-if="flag">
        <span>兑换码:</span>
        <el-input
          v-model="couponInfo.data.exchange_code"
          placeholder="请输入兑换码"
        ></el-input>
      </div>
      <div class="coupon-item">
        <span>发放量:</span>
        <el-input
          v-model="couponInfo.data.total"
          type="number"
          placeholder="请输入发放量"
        ></el-input>
      </div>
      <div class="coupon-item">
        <span>备注:</span>
        <el-input
          v-model="couponInfo.data.remark"
          placeholder="请输入备注"
        ></el-input>
      </div>
      <el-button
        class="coupon-btn"
        type="primary"
        @click="debounce(save, 1000)"
        >{{ couponInfo.data.coupon_code_id ? "保存" : "生成" }}</el-button
      >
    </go-dialog>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";
import switchText from "@/components/common/SwitchText";

export default defineComponent({
  components: { switchText },
  setup(props, { root }) {
    const flag = ref(true);
    const total = ref(10);
    const visible = ref(false);
    const couponList = ref([]);
    const couponInfo = reactive({
      data: {},
    });
    const oldHeader = [
      { name: "兑换码", value: "exchange_code" },
      { name: "优惠券组", value: "coupon_group_name" },
      { name: "优惠券名", value: "coupon_name" },
      { name: "发放量", value: "used" },
      { name: "库存", value: "total", slot: "total" },
      { name: "备注", value: "remark" },
      { name: "操作人", value: "admin_name" },
      {
        name: "创建时间",
        value: "created_at",
        slot: "time",
        width: 160,
        sort: "custom",
      },
      { name: "状态", value: "status", width: 100, slot: "state" },
      { name: "操作", value: "", slot: "control", width: 100 },
    ];
    const header = ref(oldHeader);
    watch(visible, (val) => {
      if (!val) {
        couponInfo.data = {};
      }
    });
    watch(flag, (val) => {
      params.page = 1
      if (val) {
        params.type = 2;
        header.value = oldHeader;
      } else {
        params.type = 1;
        header.value = oldHeader.slice(1, oldHeader.length);
      }
      getList();
    });
    watch(
      () => couponInfo.data.b_coupon_group_id,
      (val) => {
        getCoupon(val);
      }
    );
    const params = reactive({
      coupon_group_id: undefined,
      keyword: "",
      is_show_page: 1,
      page: 1,
      per_page: 10,
      type: 2,
      status: null,
      order_by: "coupon_code_id,desc",
    });
    const groupList = ref([]);
    const list = reactive({ data: [] });
    const getList = () => {
      root.$axios
        .get("/coupons/couponCodes", { params })
        .then((res) => {
          list.data = res.data.result;
          total.value = res.data.pagination.total;
        });
    };
    const sortChange = (val) => {
      params.order_by = "coupon_code_id,desc";
      if (val.prop === "created_at" && val.order === "descending") {
        params.order_by = "created_at,desc";
      }
      if (val.prop === "created_at" && val.order === "ascending") {
        params.order_by = "created_at,asc";
      }
      getList();
    };
    const getCoupon = (num) => {
      root.$axios
        .get(`/coupons/groups/${num}/coupon`)
        .then((res) => {
          couponList.value = res.data;
        });
    };
    const getGroup = () => {
      try {
        root.$axios
          .get("/coupons/groups", {
            params,
          })
          .then((res) => {
            groupList.value = res.data.result;
          });
      } catch (err) {
        root.$message.error(err)
      }
    };
    getGroup();
    const bannerEdit = async (data) => {
      if (data.coupon_code_id && flag) {
        couponInfo.data = data;
      }
      visible.value = true;
    };
    const save = async () => {
      const type = flag.value ? 2 : 1;
      couponInfo.data.coupon_group_id = couponInfo.data.b_coupon_group_id;
      if (!couponInfo.data.coupon_group_id) {
        root.$message.warning("请选择优惠券组");
        return;
      }
      if (!couponInfo.data.coupon_id && couponInfo.data.coupon_id !== 0) {
        root.$message.warning("请选择优惠券");
        return;
      }
      if (type === 2 && !couponInfo.data.exchange_code) {
        root.$message.warning("请输入兑换码");
        return;
      }
      if (!couponInfo.data.total) {
        root.$message.warning("请输入发放量");
        return;
      }
      if (couponInfo.data.coupon_code_id && flag) {
        root.$axios.patch(
          `/coupons/couponCodes/${couponInfo.data.coupon_code_id}`,
          couponInfo.data
        )
        .then(res=>{
          root.$message.success(res.msg);
          getList();
          visible.value = false;
        })
        
      } else {
        root.$axios.post(
          `/coupons/couponCodes/${type}/store`,
          couponInfo.data
        )
        .then(res=>{
          root.$message.success(res.msg);
          getList();
          visible.value = false;
        })
        
      }
    };
    const open = async (data) => {
      const state = data.status == 1 ? 0 : 1;
        const confirm = await root.$goDialog(
          `确定要${state === 1 ? "启用" : "停用"}吗？`
        );
        if (!confirm) return;
        const res = await root.$axios.patch(
          `/coupons/couponCodes/${data.coupon_code_id}`,
          {
            status: state,
            exchange_code: data.exchange_code,
            total: data.total,
            remark: data.remark,
          }
        )
        .then(res=>{
          root.$message.success(res.msg);
          getList();
        })
        
    };
    const read = (data) => {
      root.$router.push(
        `/market/couponManager/codeDetail?id=${data.coupon_code_id}&name=${data.coupon_name}&groupName=${data.coupon_group_name}`
      );
    };
    const reset = () => {
      Object.assign(params, {
        coupon_group_id: undefined,
        keyword: "",
        is_show_page: 1,
        page: 1,
        per_page: 20,
        type: flag.value ? 2 : 1,
        status: null,
      });
      getList();
    };
    const search = () => {
      params.page = 1;
      getList();
    };
    const handleSizeChange = (size) => {
      params.per_page = size;
      params.page = 1
      getList();
    };
    const handleCurrentChange = (index) => {
      params.page = index;
      getList();
    };
    getList();
    return {
      total,
      visible,
      flag,
      couponInfo,
      params,
      couponList,
      groupList,
      list,
      header,
      sortChange,
      getList,
      search,
      reset,
      open,
      read,
      bannerEdit,
      handleSizeChange,
      handleCurrentChange,
      save,
    };
  },
});
</script>
<style lang="scss" scoped>
@mixin circle() {
  position: absolute;
  content: " ";
  display: block;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: $safeColor;
}
::v-deep .el-tooltip {
  // width: unset !important;
}
.table {
  width: 100%;
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
}
.search-top {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  & > div {
    margin-bottom: 15px;
    width: 31%;
    display: flex;
    align-items: center;
    margin-right: 15px;
    & > span {
      font-size: 14px;
      white-space: nowrap;
      text-align: right;
      width: 70px;
      flex-shrink: 0;
    }
    &.input {
      width: 37%;
    }
  }
}
.header {
  border-top: 1px solid #eee;
  padding-top: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
}
.status {
  color: $safeColor;
  position: relative;
  color: $safeColor;
  padding-left: 13px;
  &::after {
    @include circle();
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  &.active {
    color: $wordColor;
    &::after {
      background-color: $wordColor;
    }
  }
}
.control {
  display: flex;
  span {
    margin-right: 14px;
    cursor: pointer;
    color: $mainColor;
    &.active {
      color: $dangerColor;
    }
    &:last-of-type(1) {
      margin-right: 0;
    }
  }
}
.table-img {
  width: 100%;
  object-fit: contain;
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.coupon-item {
  margin-top: 15px;
  display: flex;
  align-items: center;
  span {
    white-space: nowrap;
    width: 100px;
  }
  div {
    width: 100%;
  }
}
.coupon-btn {
  margin: 0 auto;
  display: block;
  margin-top: 15px;
}
</style>
